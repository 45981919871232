import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addNewGame, getGame, deleteGame } from "../asyncThunk/userActions";

const url = process.env.REACT_APP_SERVER_URL || "http://localhost:3004";

export const loadAuth = createAsyncThunk("user/loadAuth", async function () {
  const response = await fetch(`${url}/me`);
  const user = await response.text();

  return JSON.parse(user);
});

export const getGamesData = createAsyncThunk(
  "user/getGamesData",
  async function () {
    console.log("zapROS");
    const response = await fetch(`${url}/me/${"1068283216"}`);
    const result = await response.text();

    return JSON.parse(result);
  }
);

const initialState = {
  status: null,
  actionStatus: null,
  data: null,
  messages: [],
  selectedGameId: null,
  showDialog: {
    isOpen: null,
    type: null,
  },
  game: null,
  games: null,
};

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    exitHandle: (state) => {
      state.data = null;
      localStorage.removeItem("user");
    },
    clearMessages: (state) => {
      state.messages = [];
    },
    selectProject: (state, action) => {
      state.selectedGameId = action.payload;
    },
    resetSelectedProject: (state) => {
      state.selectedGameId = null;
    },
    closeDialog: (state) => {
      state.showDialog = {
        isOpen: false,
        type: null,
      };
    },
    showDialog: (state, action) => {
      state.showDialog = {
        isOpen: true,
        type: action.payload,
      };
    },
    hideDialog: (state) => {
      state.showDialog = false;
    },
    addNode: (state, action) => {
      const { data } = action.payload;
      state.game.nodes = [...state.game.nodes, ...data];
    },
    addEdges: (state, action) => {
      const { data } = action.payload;
      state.game.edges = [...state.game.edges, ...data];
    },
  },
  extraReducers: (build) => {
    build.addCase(loadAuth.pending, (state) => {
      state.status = "loading";
    });
    build.addCase(loadAuth.fulfilled, (state, action) => {
      state.status = null;
      state.data = action.payload;

      state.gamesLinks = action.payload.games.map((el) => {
        return {
          _id: el._id,
          title: el.title,
        };
      });
      localStorage.setItem("user", action.payload.tgId);
    });
    build.addCase(loadAuth.rejected, (state) => {
      state.status = null;
      // state.data = null;
    });
    build.addCase(getGamesData.pending, (state) => {
      state.status = "loading";
      state.games = null;
    });
    build.addCase(getGamesData.fulfilled, (state, action) => {
      state.status = null;
      state.gamesLinks = action.payload.map((el) => {
        return {
          _id: el._id,
          title: el.title,
        };
      });
      state.games = [...action.payload];
      localStorage.setItem("user", action.payload.tgId);
    });
    build.addCase(getGamesData.rejected, (state) => {
      state.status = null;
      // state.data = null;
    });
    build.addCase(addNewGame.fulfilled, (state) => {
      state.actionStatus = null;
      state.messages = [
        ...state.messages,
        {
          type: "info",
          message: "Игра создана",
        },
      ];
      state.showDialog = {
        isOpen: false,
        type: null,
      };
      state.selectedGameId = null;
    });
    build.addCase(addNewGame.pending, (state) => {
      state.actionStatus = "loading";
    });
    build.addCase(addNewGame.rejected, (state, action) => {
      state.messages = [...state.messages, action.payload];
    });
    build.addCase(getGame.fulfilled, (state, action) => {
      state.status = null;
      state.game = action.payload;
    });
    build.addCase(getGame.pending, (state) => {
      state.status = "loading";
    });
    build.addCase(getGame.rejected, (state) => {
      state.status = null;
      // state.data = null;
    });
    build.addCase(deleteGame.pending, (state) => {
      state.actionStatus = "loading";
    });
    build.addCase(deleteGame.fulfilled, (state, action) => {
      state.messages = [...state.messages, action.payload];
      state.showDialog = {
        isOpen: false,
        type: null,
      };
      state.actionStatus = null;
      state.game = null;
      state.selectedGameId = null;
    });
    build.addCase(deleteGame.rejected, (state, action) => {
      state.messages = [...state.messages, action.payload];
      state.actionStatus = null;
    });
  },
});

export const userSlice = slice.actions;
export default slice.reducer;

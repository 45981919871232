import { useCallback, useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactFlow, {
  addEdge,
  Background,
  useNodesState,
  useEdgesState,
  MiniMap,
  Controls,
  ReactFlowProvider,
  getIncomers,
  getOutgoers,
  getConnectedEdges,
} from "reactflow";
import { saveGame } from "../redux/asyncThunk/userActions";
import { userSlice } from "../redux/user/userSlice";
import { Button } from "@mui/material";
import { NodeWithToolbar } from "./nodes/NodeWithToolBar";
import { FirstSceneNode } from "./nodes/FirstSceneNode";
import "reactflow/dist/style.css";
import "./index.css";
import { useLocation } from "react-router-dom";
import Settings from "./Settings";

const { showDialog } = userSlice;

const nodeTypes = {
  "first-scene-node": FirstSceneNode,
  "node-with-toolbar": NodeWithToolbar,
};

const Flow = (props) => {
  const { game } = props;
  const dispatch = useDispatch();

  const location = useLocation();
  const { type } = location.state || {};

  const selectedGameId = useSelector((state) => state.user?.selectedGameId);

  const reactFlowWrapper = useRef(null);
  const connectingNodeId = useRef(null);

  const [viewMode, setViewMode] = useState("");
  const [nodes, setNodes, onNodesChange] = useNodesState(game?.nodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(game?.edges);

  useEffect(() => {
    if (type === "settings") {
      setViewMode(type);
    }
  }, [type]);

  useEffect(() => {
    setNodes(game?.nodes);
    setEdges(game?.edges);
  }, [game, setEdges, setNodes]);

  const onConnect = useCallback(
    (connection) => {
      setEdges((eds) => addEdge(connection, eds));
    },
    [setEdges]
  );

  const onConnectStart = useCallback((_, { nodeId }) => {
    connectingNodeId.current = nodeId;
  }, []);

  const onConnectEnd = useCallback(
    (event) => {
      if (!connectingNodeId.current) return;

      const targetIsPane = event.target.classList.contains("react-flow__pane");

      if (targetIsPane) {
        let newId = Date.now();

        const strID = newId.toString();
        const groupNode = {
          id: `${strID}-group`,
          type: "group",
          position: {
            x: event.clientX,
            y: event.clientY,
          },
          style: {
            backgroundColor: "rgba(45, 157, 188, 0.5)",
            width: 200,
            height: 160,
          },
        };

        const newNode = {
          id: strID,
          data: { label: `Сцена ${strID}` },
          position: {
            x: 0,
            y: 13,
          },
          type: "node-with-toolbar",
          targetPosition: "left",
          className: "light",
          style: {
            backgroundColor: "rgba(125, 157, 188, 0.5)",
            width: 200,
            height: 100,
          },
          extent: "parent",
          parentNode: `${strID}-group`,
          parentId: `${strID}-group`,
          draggable: false,
        };

        setNodes((nds) => nds.concat([groupNode, newNode]));
        setEdges((eds) =>
          eds.concat({
            id: strID + Math.random(),
            source: connectingNodeId.current,
            target: strID,
            type: "input",
          })
        );
        // dispatch(addNode({ data: [groupNode, newNode] }));
      }
    },
    [nodes, setEdges, setNodes]
  );

  const onNodesDelete = useCallback(
    (deleted) => {
      setEdges(
        deleted.reduce((acc, node) => {
          const incomers = getIncomers(node, nodes, edges);
          const outgoers = getOutgoers(node, nodes, edges);
          const connectedEdges = getConnectedEdges([node], edges);

          const remainingEdges = acc.filter(
            (edge) => !connectedEdges.includes(edge)
          );

          const createdEdges = incomers.flatMap(({ id: source }) =>
            outgoers.map(({ id: target }) => ({
              id: `${source}->${target}`,
              source,
              target,
            }))
          );

          return [...remainingEdges, ...createdEdges];
        }, edges)
      );
    },
    [setEdges, edges, nodes]
  );

  return (
    <div className="wrapper" ref={reactFlowWrapper}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "10px 10px",
          gap: "10px",
        }}
      >
        <Button
          variant="contained"
          size="small"
          onClick={() =>
            dispatch(
              saveGame({
                selectedGameId,
                nodes,
                edges,
              })
            )
          }
        >
          Сохранить
        </Button>

        {viewMode === "settings" ? (
          <Button
            variant="outlined"
            size="small"
            onClick={() => setViewMode("scheme")}
          >
            Схема проекта
          </Button>
        ) : (
          <Button
            variant="outlined"
            size="small"
            onClick={() => setViewMode("settings")}
          >
            Настройки проекта
          </Button>
        )}

        <Button
          variant="contained"
          onClick={() => dispatch(showDialog("delete"))}
          color="error"
          size="small"
        >
          Удалить
        </Button>
      </div>
      {viewMode === "settings" ? (
        <Settings />
      ) : (
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onNodesDelete={onNodesDelete}
          onConnect={onConnect}
          onConnectStart={onConnectStart}
          onConnectEnd={onConnectEnd}
          fitView
          className="react-flow-subflows-example"
          nodeTypes={nodeTypes}
        >
          <MiniMap />
          <Controls />
          <Background />
        </ReactFlow>
      )}
    </div>
  );
};

function Workarea(props) {
  const { game } = props;

  return (
    <ReactFlowProvider>
      <Flow {...props} game={game} />
    </ReactFlowProvider>
  );
}

export default Workarea;

import { useEffect, useState } from "react";
import "./index.css";
import InputFileUpload from "./UploadFile";
import { useSelector } from "react-redux";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { FormControl, Button, TextField } from "@mui/material";

export default function Settings() {
  const [data, setData] = useState();
  const [value, setValue] = useState("В работе");

  const game = useSelector((state) => state.user.game);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    if (game) {
      setData({
        ...game,
        scenesValue: game.nodes.filter((el) => el.type === "group")?.length,
      });
    }
  }, [game]);
  console.log(data);
  return (
    <div
      style={{
        marginTop: "30px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "10px",
        height: "1000px",
      }}
    >
      <div style={{ textAlign: "center" }}>
        <h2>Настройки</h2>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          width: "1440px",
          marginTop: "30px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <div className="settingsRow">
            <div>Название проекта</div>
            <div>
              <TextField
                id="outlined-basic"
                label="Введите название"
                variant="outlined"
                size="small"
                value={data?.title}
                sx={{
                  width: "300px",
                }}
              />
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <InputFileUpload
            // handleAddPoster={handleAddPoster}
            // id={el._id}
            />
          </div>
          <div className="settingsRow">
            <div>Доступ к проекту:</div>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                sx={{ width: "300px", justifyContent: "space-between" }}
              >
                <FormControlLabel
                  value="Публичный"
                  control={<Radio />}
                  label="Публичный"
                />
                <FormControlLabel
                  value="Приватный"
                  control={<Radio />}
                  label="Приватный"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div className="settingsRow">
            <div>Статус проекта</div>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={value}
                onChange={handleChange}
                sx={{ width: "300px", justifyContent: "space-between" }}
              >
                <FormControlLabel
                  value="Опубликовать"
                  control={<Radio />}
                  label="Опубликовать"
                />
                <FormControlLabel
                  value="В работе"
                  control={<Radio />}
                  label="В работе"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div className="settingsRow">
            <div>Описание к проекту</div>
            <TextField
              id="outlined-basic"
              label="Введите текст и сохраните проект после редактирования"
              variant="outlined"
              multiline="true"
              minRows={"3"}
              width="300px"
              sx={{
                width: "300px",
              }}
            />
          </div>
          <div className="settingShortRow">
            <div>Количество сцен:</div>
            <div>{data?.scenesValue}</div>
          </div>
          <div className="settingShortRow">
            <div>Лимит доступов:</div>
            <div>{data?.limit}</div>
          </div>
          {/* <div className="settingShortRow">
            <div>Лимит доступов:</div>
            <div>{data?.limit}</div>
          </div> */}
        </div>

        <div style={{ textAlign: "center" }}>
          <h4>Стартовый пост вашей игры</h4>
          <div
            style={{
              border: "2px solid black",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              width: "400px",
              height: "350px",
              flexDirection: "column",
            }}
          >
            <div
              className="image-preview"
              style={{
                boxSizing: "border-box",
                height: "220px",
                width: "300px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                src={`data:image/jpeg;base64,` + data?.poster}
                alt="Uploaded"
              />
            </div>
            <div>{data?.description}</div>
            <Button variant="contained" size="small">
              Запустить
            </Button>
          </div>
        </div>

        <div style={{ textAlign: "center" }}>
          <h4>Пример стартового поста</h4>
          <div
            style={{
              border: "2px solid black",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              width: "400px",
              height: "350px",
              flexDirection: "column",
            }}
          >
            <div
              className="image-preview"
              style={{
                height: "220px",
                width: "300px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                style={{
                  objectFit: "cover",
                }}
                src={process.env.PUBLIC_URL + "/images/bg.png"}
                alt="Background"
              />
            </div>
            <div
              style={{
                width: "90%",
                border: "1px solid #305478",
                borderRadius: "10px",
                backgroundColor: "#305478",
                color: "white",
                padding: "3px 0",
              }}
            >
              <p>Привет! Ты готов начать увлекательное приключение со мной?</p>
            </div>
            <Button variant="contained" size="small">
              Запустить
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

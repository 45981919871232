import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NodeToolbar, Handle, Position } from "reactflow";
import { loadVideoScene } from "../../redux/asyncThunk/userActions";
import "reactflow/dist/style.css";
import "../index.css";
import { userSlice } from "../../redux/user/userSlice";

import { Box, TextField, Button } from "@mui/material";

const { addNode } = userSlice;

export function NodeWithToolbar(props) {
  const { data, isConnectable, id } = props;

  const selectedGameId = useSelector((state) => state.user?.selectedGameId);
  const game = useSelector((state) => state.user?.game);

  const [video, setVideo] = useState(false);
  const [buttonText, setButtonText] = useState("");
  const [showTextInput, setShowTextInput] = useState(false);

  const dispatch = useDispatch();

  const handleVideoChange = (e) => {
    const file = e.target.files[0];

    dispatch(loadVideoScene({ selectedGameId, scene: id, file }));
  };

  const handleAddButtonNode = () => {
    const checkButtonsInNode = game.nodes.find(
      (el) => el.id.split("-")[0] === id && el.type === "input"
    );

    const buttonPosiiton = {
      x: 0,
      y: 0,
    };

    const newId = !checkButtonsInNode
      ? `${id}-${Number(id) + 1}`
      : `${id}-${Number(checkButtonsInNode.id.split("-")[1]) + 1}`;

    if (!checkButtonsInNode) {
      buttonPosiiton.y = 140;
    } else {
      buttonPosiiton.y = 140;
    }

    const newButtonNode = {
      id: newId,
      data: { label: buttonText },
      position: buttonPosiiton,
      style: {
        width: 200,
        height: 20,
      },
      parentId: `${id}-group`,
      sourcePosition: "right",
      type: "input",
      extent: "parent",
      parentNode: `${id}-group`,
      draggable: false,
    };

    dispatch(addNode({ data: [newButtonNode] }));
  };

  return (
    <>
      <NodeToolbar
        isVisible={data?.forceToolbarVisible || undefined}
        position={data?.toolbarPosition}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: "7px" }}>
          <Box sx={{ display: "flex", justifyContent: "center", gap: "5px" }}>
            {showTextInput ? (
              <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
                <TextField
                  id="outlined-basic"
                  label="Текст кнопки"
                  variant="outlined"
                  size="small"
                  onChange={(event) => setButtonText(event.target.value)}
                />
                <Button
                  variant="contained"
                  onClick={() => handleAddButtonNode()}
                >
                  Добавить
                </Button>
              </Box>
            ) : null}
          </Box>
          <Box sx={{ display: "flex" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <button
                className="toolbar_button"
                onClick={() => setVideo(!video)}
              >
                Добавить видео
              </button>
              {video ? (
                <input
                  type="file"
                  name="videoFile"
                  accept="video/*"
                  onChange={handleVideoChange}
                />
              ) : null}
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "5px" }}
            >
              <button
                className="toolbar_button"
                onClick={() => setShowTextInput(!showTextInput)}
              >
                Добавить кнопку
              </button>
            </div>
            <button className="toolbar_button">Удалить</button>
          </Box>
        </Box>
      </NodeToolbar>
      <Handle
        type="target"
        position={Position.Left}
        style={{ background: "#555" }}
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
        }}
      >
        <div
          style={{
            height: "180px",
            border: "1px solid white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#2F5478",
            width: "200px",
          }}
        >
          {data?.hasVideo ? (
            <video width="200" height="140" controls>
              <source src={data.videoUrl} type="video/mp4" />
            </video>
          ) : (
            "Загрузите видео"
          )}
        </div>
      </div>
    </>
  );
}

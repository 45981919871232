import { Button } from "@mui/material";
import { userSlice } from "../redux/user/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

const { selectProject, showDialog, resetSelectedProject } = userSlice;

export default function SideMenu() {
  const dispatch = useDispatch();

  const games = useSelector((state) => state.user?.gamesLinks);
  const selectedGameId = useSelector((state) => state.user?.selectedGameId);

  const handleActive = (id) => {
    dispatch(selectProject(id));
  };

  return (
    <div className="sideMenu">
      {/* <Link to={"/main/new"} state={{ type: "new" }}> */}
      <Button
        variant="contained"
        size="small"
        onClick={() => dispatch(showDialog("new"))}
      >
        Создать игро-фильм
      </Button>
      {/* </Link> */}

      <div style={{ padding: "10px 0" }}>
        <Link to={"/main"}>
          <Button
            variant="contained"
            size="small"
            onClick={() => dispatch(resetSelectedProject())}
            color="success"
          >
            Рабочий стол
          </Button>
        </Link>
      </div>

      <div
        style={{
          padding: "20px 0px",
          textAlign: "center",
          width: "100%",
          color: "white",
        }}
      >
        <p style={{ fontWeight: "bold" }}>Ваши игры:</p>
        {!games?.length ? <div>У вас пока нет созданных игр</div> : null}
        <ul>
          {games?.map((el, i) => {
            return (
              <Link
                to={`/main/${el._id}`}
                state={{ projectId: el._id, type: "edit" }}
                style={{ textDecoration: "none" }}
                key={el._id + i}
              >
                <li
                  onClick={() => handleActive(el._id)}
                  style={{
                    display: "flex",
                    cursor: "pointer",
                    padding: "5px 0",
                    margin: "5px 0",
                    justifyContent: "center",
                    textDecoration: "none",
                    color: selectedGameId === el._id ? "#305478" : "white",
                    backgroundColor: selectedGameId === el._id ? "white" : "",
                    fontWeight: "bold",
                  }}
                >
                  {el.title}
                </li>
              </Link>
            );
          })}
        </ul>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          <div style={{ width: "80px", borderBottom: "1px solid white" }}></div>
        </div>
        <p style={{ fontWeight: "bold", marginTop: "10px" }}>Примеры:</p>
      </div>
    </div>
  );
}
